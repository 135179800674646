type SessionStorageKeys = 'deletedFields'

export default class ApiSessionStorage {
  private static coreApiSessionStorage: { [key in SessionStorageKeys]?: any } = {}

  get(key: SessionStorageKeys) {
    return ApiSessionStorage.coreApiSessionStorage[key]
  }

  set(key: SessionStorageKeys, val: any) {
    ApiSessionStorage.coreApiSessionStorage[key] = val
  }
}
